<template>
  <a-spin :spinning="ifSub">
    <a-row :gutter="24" :style="{height: bodyHeight-20 + 'px','overflow-y': 'auto'}">
      <a-col :span="8" :style="{height: bodyHeight-20 + 'px','overflow-y': 'auto'}">
        <a-descriptions :column="1" style="margin-left: 10px;font-size: 20px" title="清运任务">
          <a-descriptions-item v-for="reportRecord in reports" :key="reportRecord.reportId"
                               style="text-align: left;padding: 10px;">
            <a-icon style="color:#2ba245" type="wechat" v-if="reportRecord.collectType==='WECHAT'" theme="filled"/>
            <a-icon style="color:#2b97ff" type="phone" v-if="reportRecord.collectType==='REGISTER'" theme="filled"/>
            <a-icon style="color:#3686a2" type="import" v-if="reportRecord.collectType==='IMPORT'"/>
            <a-divider type="vertical"/>
            {{reportRecord.userName}}
            <a-divider type="vertical"/>
            <a-tag v-if="reportRecord.type===spotTypeEnum.DANWENG.value" color="#00BFFF">
              单翁
            </a-tag>
            <a-tag v-if="reportRecord.type===spotTypeEnum.JIWUCHI.value" color="#00CD66">
              集污池
            </a-tag>
            <a-tag v-if="reportRecord.type===spotTypeEnum.SANGE.value" color="#00CED1">
              三格化粪池
            </a-tag>
            <a-tag v-if="reportRecord.type===spotTypeEnum.LANI.value" color="#00CED1">
              拉泥
            </a-tag>
            <a-tag v-if="reportRecord.type===spotTypeEnum.GONGCE.value" color="#00CED1">
              公厕
            </a-tag>
            <a-tag v-if="reportRecord.type===spotTypeEnum.OTHER.value" color="#00CED1">
              其他
            </a-tag>
            <a-divider type="vertical"/>
            {{reportRecord.areaName}}
            <a-badge v-if="reportRecord.reportBindStatus==='COMPLETE'" status="success"/>
            <a-badge v-if="reportRecord.reportBindStatus==='DOING'" status="processing"/>
            <span><a-icon type="clock-circle"/>&nbsp;{{reportRecord.time|formatDate}} </span>
            <a-icon style="color: #9E81FF;" type="environment" @click="makerSpot(reportRecord)"/>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="1" style="margin-left: 10px;font-size: 20px;" title="中途停车点">
          <div>
            <a-list style="margin-right: 24px;" item-layout="horizontal" :data-source="carStops">
              <a-list-item slot="renderItem" slot-scope="item,index">
                <a-list-item-meta :description="item.location">
                  <span slot="title">{{ item.startTime |formatDate}}~{{ item.endTime |formatDate}}（{{ item.durationStr }}）</span>
                  <a-avatar style="color: #f56a00; backgroundColor: #fde3cf" slot="avatar">{{index+1}}</a-avatar>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </div>
        </a-descriptions>
        <a-descriptions :column="2" style="margin-left: 10px;font-size: 20px" title="清运信息">
          <a-descriptions-item label="任务状态">
            <a-tag v-if="formItem.record.status"
                   :color="formItem.record.status === 'NOT'?'':formItem.record.status === 'COMPLETED'?'#108ee9':formItem.record.status === 'DOING'?'#87d068':''">
              {{formItem.record.status === 'NOT'?'未开始':formItem.record.status ===
              'COMPLETED'?'已完成':formItem.record.status === 'DOING'?'进行中':''}}
            </a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="执行司机">{{ formItem.record.driver.name || '-' }}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="1" style="margin-left: 10px;font-size: 20px">
          <a-descriptions-item label="执行时间"
                               v-if="formItem.record.status === 'DOING' || formItem.record.status === 'COMPLETED'"> {{
            formItem.record.startTime }} ~ {{ formItem.record.endTime || '至今'
            }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="2" style="margin-left: 10px;font-size: 20px">
          <a-descriptions-item label="执行耗时"
                               v-if="formItem.record.status === 'DOING' || formItem.record.status === 'COMPLETED'">{{
            formItem.record.durationStr }}
          </a-descriptions-item>
          <a-descriptions-item label="执行公里" v-if="formItem.record.status === 'COMPLETED'">{{ formItem.record.mileage }} / {{ formItem.record.modifyMileage }}
            km
          </a-descriptions-item>
          <a-descriptions-item label="清运排污厂" v-if="formItem.record.status === 'COMPLETED'">{{
            formItem.record.destination }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="1" style="margin-left: 10px;font-size: 20px">
          <a-descriptions-item label="开始位置" v-if="formItem.record.status === 'COMPLETED'">{{
            formItem.recordTrail.startLocation }}
          </a-descriptions-item>
          <a-descriptions-item label="完成位置" v-if="formItem.record.status === 'COMPLETED'">{{
            formItem.recordTrail.endLocation }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="1" style="margin-left: 10px" title="清运图片">
          <a-descriptions-item v-if="images.length&&formItem.record.status === 'COMPLETED'">
            <template>
              <qiniu-image-upload ref="qiniuImageRef" type="edit" @getImageData="getImageData"
                                  :multiSelection="true" :items="images" style="margin-top: 15px"/>
            </template>
          </a-descriptions-item>
          <div>
            <a-button v-if="ifEditImage" style="float: right;" type="primary" shape="round" @click="confirmEditImage()">确认修改图片</a-button>
          </div>
        </a-descriptions>
      </a-col>
      <a-col :span="16">
        <a-row>
          <a-col :span="2">
            <a-descriptions :column="1" title="行车轨迹"></a-descriptions>
          </a-col>
          <a-col :span="22">
            <a-button size="small" type="primary" icon="sync" @click="handleRunTrail()">
              轨迹回放
            </a-button>
          </a-col>
        </a-row>
        <div :id="loopRecordMapContainerId" :style="{height: bodyHeight-64 + 'px'}"></div>
        <!--          <div>{{ mapStyle }}</div>-->
        <div class="layer-container" style="width: 80px;height:30px;text-align:center;position: absolute;top: 60px; right: 20px;margin-right: 10px; border-radius: 5px;background: #ffffff;">
          <div class="layer-label">
            <a-dropdown>
              <div class="drop-container">
                <div class="ant-modal-title drop-container-title" style="line-height: 30px;">
                  <template v-for="item in mapStyleItems">
                    <div class="default-hover cursor-pointer">
                      <span v-if="item.value === chooseMapStyle">  {{ item.label }}</span>
                    </div>
                  </template>
                </div>
              </div>
              <a-menu slot="overlay">
                <a-menu-item v-for="item in mapStyleItems" :key="item.value">
                  <a target="_blank" @click="chooseMapStyleHandle(item)">{{ item.label }} &nbsp;&nbsp;<a-icon
                    type="check"
                    style="color: #1e88e5; margin-top: 4px;"
                    v-if="chooseMapStyle === item.value"
                    class="f-r"/>
                  </a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { carLoopRecordFormVO } from './common/carLoopRecordFormVO'
  import UUID from '../../../../utils/UUID'
  import { lazyAMapApiLoaderInstance } from 'vue-amap'
  import '../../../../core/amap'
  import mapStyle from '../../../map/composite/mixins/map-style'
  import { mapState } from 'vuex'
  import xunjianPassed from '../../../map/composite/mixins/xunjian-passed'
  import QiniuImageUpload from '../../../../components/QiniuUpload/QiniuImageUpload'
  import moment from 'moment'
  import { spotTypeEnum } from '@/constant/spot/spotType'

  export default {
    name: 'LoopRecordDetail',
    components: { QiniuImageUpload },
    mixins: [mapStyle, xunjianPassed],
    filters: {
      formatDate (date) {
        if (date) {
          return moment(date).format('MM-DD HH:mm')
        } else {
          return '~'
        }
      }
    },
    data () {
      return {
        ifEditImage: false,
        loopRecordId: null,
        images: [],
        imageIds: [],
        ifSub: false,
        formItem: carLoopRecordFormVO(),
        labelCol: { span: 6 },
        wrapperCol: { span: 17 },
        /*地图*/
        loopRecordMapContainerId: new UUID().id,
        recordMap: undefined,
        marker: null,
        pathArray: null,
        warningArray: null,
        styleMapHeight: this.bodyHeight,
        reports: [],
        carStops: [],
        spotMaker: {}, //清运任务集污点标记点
        chooseMapStyle: this.$store.state.common.mapStyle,
        mapStyleItems: [
          {
            value: 'normal',
            label: '标准'
          },
          {
            value: 'dark',
            label: '幻影黑'
          },
          {
            value: 'light',
            label: '月光银'
          },
          {
            value: 'whitesmoke',
            label: '远山黛'
          },
          {
            value: 'fresh',
            label: '草色青'
          },
          {
            value: 'grey',
            label: '雅士灰'
          },
          {
            value: 'graffiti',
            label: '涂鸦'
          },
          {
            value: 'macaron',
            label: '马卡龙'
          },
          {
            value: 'darkblue',
            label: '靛青蓝'
          },
          {
            value: 'wine',
            label: '酱籽'
          },
          {
            value: 'satellite',
            label: '卫星图'
          }
        ],
        sewagePlantOverlays: [],
        sewagePlantOverlayGroup: undefined,
        spotTypeEnum: spotTypeEnum
      }
    },
    computed: {
      ...mapState({
        bodyHeight: (state) => {
          return state.app.bodyHeight - 20
        }
      })
    },
    methods: {
      /**
       * 初始化地图
       */
      initMap () {
        this.currentMapStyle = this.$store.state.common.mapStyle
        lazyAMapApiLoaderInstance.load().then(() => {
          this.recordMap = new AMap.Map(this.loopRecordMapContainerId, {
            resizeEnable: true,
            mapStyle: 'amap://styles/' + this.currentMapStyle,
            center: [106.230909, 38.487193],
            zoom: 11
          })
          //处理卫星图
          if (this.currentMapStyle === 'satellite') {
            this.recordMap.add([this.getSatelliteLayer()])
          } else {
            this.recordMap.remove([this.getSatelliteLayer()])
            this.recordMap.setMapStyle('amap://styles/' + this.currentMapStyle)
          }
          this.buildMapOtherLayer()
          // this.getDevicesOverlayGroup()
          if (
            this.formItem.recordTrail &&
            this.formItem.recordTrail.position &&
            this.formItem.recordTrail.position !== '[]'
          ) {
            this.drawLine(this.formItem.id, this.formItem.recordTrail.position)
            this.drawCarStopPoints()
          }
        })
        this.getSewagePlant()
      },
      /*初始化加载所有污水处理厂信息*/
      getSewagePlant () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.sewagePlantApi.select,
          data: {},
          loading: 'no',
          noTips: true,
          success: (data) => {
            this.buildSewagePlant(data)
          },
          error: () => {
          }
        })
      },
      buildSewagePlant (data) {
        this.buildSewagePlantMarker(data.body)
        if (!this.recordMap) return
        this.recordMap.add(this.sewagePlantOverlays)
      },
      /*污水厂图标标记*/
      buildSewagePlantMarker (list) {
        this.sewagePlantOverlays = []
        list.forEach((x) => {
          // 创建 AMap.Icon 实例：
          var icon = new AMap.Icon({
            size: new AMap.Size(30, 30), // 图标尺寸
            image: 'https://sosfile.fangshuoit.com/o_1ec258ru2rtq1q69fl51oki112i1t.png', // Icon的图像
            imageSize: new AMap.Size(30, 30) // 根据所设置的大小拉伸或压缩图片
          })
          // 将 Icon 实例添加到 marker 上:
          var marker = new AMap.Marker({
            position: new AMap.LngLat(x.longitude, x.latitude),
            offset: new AMap.Pixel(-30, -14),
            title: x.name,
            icon: icon,
            zoom: 15,
            extData: {
              key: x
            }
          })
          marker.setLabel({
            offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
            content: '<div class="car-marker">' + x.name + '</div>', //设置文本标注内容
            direction: 'top' //设置文本标注方位
          })
          if ((x.longitude !== '0') && (x.latitude !== '0')) {
            // marker.on('click', this.showInfo)
            this.getSewagePlantOverlayGroup().addOverlay(marker)
            this.sewagePlantOverlays.push(marker)
          }
        })
        this.recordMap.setFitView()
      },
      getSewagePlantOverlayGroup () {
        if (this.sewagePlantOverlayGroup === undefined) {
          this.sewagePlantOverlayGroup = new AMap.OverlayGroup()
        }
        return this.sewagePlantOverlayGroup
      },
      getDevicesOverlayGroup () {
        const overlays = []
        // 设置设备覆盖物
        if (this.dbDevicesData) {
          this.dbDevicesData.forEach((item) => {
            let cover = this.buildCover(item)
            let overlay = cover.cover[0]
            if (cover.type === 'text') {
              // 设置 labelLayer 到当前地图中
              this.getLabelLayer(this.recordMap).add(overlay)
            } else {
              overlays.push(overlay)
            }
          })
        }
        this.devicesOverlayGroup = new AMap.OverlayGroup(overlays)
        this.devicesOverlayGroup.setMap(this.recordMap)
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.ifEditImage = true
        this.imageIds = []
        data.forEach(item => {
          this.imageIds.push(item.id)
        })
      },
      confirmEditImage () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carLoopRecordApi.updateImages,
          data: {
            id: this.loopRecordId,
            imageIds: this.imageIds
          },
          loading: 'spinShow',
          success: (data) => {
            this.$emit('editSuccess', data.body)
            this.ifEditImage = false
          }
        })
      },
      /*加载数据*/
      loadData (id) {
        this.loopRecordId = id
        this.formItem = carLoopRecordFormVO()
        this.$http(this, {
          url: SERVICE_URLS.csgz.carLoopRecordApi.view,
          params: { id },
          noTips: true,
          success: (data) => {
            Object.assign(this.formItem, data.body)
            // this.reportNames = eval(this.formItem.reportNames)
            this.reports = data.body.reports
            this.carStops = data.body.carStops
            this.images = data.body.images
            if (data.body.recordTrail) {
              this.formItem.recordTrail = data.body.recordTrail
            } else {
              this.formItem.recordTrail = {
                id: null,
                position: null,
                startLocation: '',
                endLocation: ''
              }
            }
            this.initMap()
          }
        })
      },
      handleRunTrail () {
        if (
          this.formItem.recordTrail &&
          this.formItem.recordTrail.position &&
          this.formItem.recordTrail.position !== '[]'
        ) {
          this.marker.moveAlong(eval(this.pathArray), 5000)
        }
      },
      /*画线方法*/
      drawLine (id, path) {
        /*默认轨迹*/
        var polyline = new AMap.Polyline({
          path: eval(path),
          strokeColor: '#2d8cf0', //线颜色--蓝色
          strokeOpacity: 1, //线透明度
          strokeWeight: 4, //线宽
          lineJoin: 'round', // 折线拐点连接处样式
          extData: {
            key: id
          }
        })
        this.recordMap.add(polyline)
        var gisArray = eval(path)
        var startPoint = eval(gisArray[0])
        var endPoint = eval(gisArray[gisArray.length - 1])
        let startIcon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(25, 34),
          // 图标的取图地址
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          // 图标所用图片大小
          imageSize: new AMap.Size(135, 40),
          // 图标取图偏移量
          imageOffset: new AMap.Pixel(-9, -3)
        })
        let startMarker = new AMap.Marker({
          map: this.recordMap,
          icon: startIcon,
          offset: new AMap.Pixel(-11, -30),
          position: [startPoint[0], startPoint[1]]
        })
        let endIcon = new AMap.Icon({
          size: new AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new AMap.Size(135, 40),
          imageOffset: new AMap.Pixel(-95, -3)
        })
        let endMarker = new AMap.Marker({
          map: this.recordMap,
          icon: endIcon,
          offset: new AMap.Pixel(-13, -33),
          position: [endPoint[0], endPoint[1]]
        })
        /*回放标记*/
        this.pathArray = path
        this.marker = new AMap.Marker({
          map: this.recordMap,
          position: [startPoint[0], startPoint[1]],
          icon: 'https://webapi.amap.com/images/car.png',
          offset: new AMap.Pixel(-26, -13),
          autoRotation: true,
          angle: -90
        })
        var passedPolyline = new AMap.Polyline({
          map: this.recordMap,
          strokeColor: '#AF5', //线颜色
          strokeWeight: 6, //线宽
          lineJoin: 'round' // 折线拐点连接处样式
        })
        this.marker.on('moving', (e) => {
          passedPolyline.setPath(e.passedPath)
        })
        /*添加到地图*/
        this.recordMap.add(startMarker)
        this.recordMap.add(endMarker)
        this.recordMap.setFitView(polyline)
        this.handleRunTrail()
      },
      /*画停车点*/
      drawCarStopPoints () {
        this.carStops.forEach((x) => {
          // 创建 AMap.Icon 实例：
          var icon = new AMap.Icon({
            size: new AMap.Size(28, 28), // 图标尺寸
            image: '/images/icon_stop_spot.png', // Icon的图像
            imageSize: new AMap.Size(28, 28) // 根据所设置的大小拉伸或压缩图片
          })
          // 将 Icon 实例添加到 marker 上:
          var marker = new AMap.Marker({
            position: new AMap.LngLat(x.longitude, x.latitude),
            offset: new AMap.Pixel(-10, -26),
            title: this.getCarStopName(x),
            icon: icon,
            angle: 0,
            zoom: 15,
            extData: {
              key: x.id
            }
          })
          /*marker.setLabel({
            offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
            content: '<div class="car-marker-info">' + '停车点' + '</div>', //设置文本标注内容
            direction: 'top' //设置文本标注方位
          })*/
          if ((x.longitude !== '0') && (x.latitude !== '0')) {
            this.recordMap.add(marker)
            this.recordMap.setFitView()
          }
        })
      },
      getCarStopName (item) {
        return '停留时长：' + item.startTime + '~' + item.startTime + ' (' + item.durationStr + ')\n' +
          '停留位置：' + item.location
      },
      //集污点位置闪烁
      makerSpot (x) {
        //清除原闪烁点
        this.recordMap.remove(this.spotMaker)
        this.spotMaker = new AMap.Marker({
          position: new AMap.LngLat(x.longitude, x.latitude),
          // offset: new AMap.Pixel(-10, -26),
          content: '<div class="markerClass"></div>',
          angle: 0,
          zoom: 15,
          extData: {
            key: x.id
          }
        })
        if ((x.longitude !== '0') && (x.latitude !== '0')) {
          this.recordMap.add(this.spotMaker)
          this.recordMap.setFitView()
        }
      },
      chooseMapStyleHandle (item) {
        this.chooseMapStyle = item.value
        if (this.recordMap) {
          //处理卫星图
          if (this.chooseMapStyle === 'satellite') {
            this.recordMap.add([this.getSatelliteLayer()])
          } else {
            this.recordMap.remove([this.getSatelliteLayer()])
            this.recordMap.setMapStyle('amap://styles/' + item.value)
          }
          //保存当前选中主题
          this.$store.commit('SET_MAP_STYLE', item.value)
        }
      }
    }
  }
</script>
<style>
  .markerClass {
    width: 40px;
    height: 40px;
    position: relative;
    display: block;
    outline: none;
    box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.75);
    border-radius: 100%;
    background-color: #ff0000;
    -webkit-animation: ani-spin-bounce 1s 0s ease-in-out infinite;
    animation: ani-spin-bounce 1s 0s ease-in-out infinite;
    opacity: 0.9;
  }

  @keyframes ani-spin-bounce {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
</style>