<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">清运记录</h1>
    </div>
    <div slot="extra">
      <a-button style="margin-left: 16px;" icon="user" @click="exportByDriver" :loading="exportDriverLoading" type="primary">按司机导出</a-button>
      <a-button style="margin-left: 16px;" icon="car" @click="exportByCar" :loading="exportCarLoading" type="primary">按车辆导出</a-button>
      <a-button style="margin-left: 16px;" icon="export" @click="exportWord" :loading="exportWordLoading" type="default">导出统计</a-button>
      <a-button style="margin-left: 16px;" icon="export" @click="confirmExportFile" :loading="exportImagesLoading" type="default">导出图片</a-button>
    </div>
    <a-card :bordered="false" style="margin-top: 15px;">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="执行司机">
              <a-tree-select
                style="width: 150px;"
                allowClear
                :treeData="userList"
                v-model="executeUserStr"
                @change="beforeSearch"
                placeholder="请选择执行司机">
              </a-tree-select>
            </a-form-item>
            <a-form-item label="执行车辆">
              <a-select
                style="width: 150px;"
                allowClear
                showSearch
                optionFilterProp="label"
                v-model="searchParams.carInfoId"
                placeholder="请选择执行车辆"
                @change="search">
                <a-select-option v-for="item in carList" :key="item.id" :value="item.id" :label="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="执行时间">
              <a-range-picker @change="dateOnChange" v-model="dateSearch" style="width: 260px;">
                <a-icon slot="suffixIcon" type="smile"/>
              </a-range-picker>
            </a-form-item>
            <a-form-item label="任务状态">
              <a-radio-group @change="selectStatus" v-model="searchParams.status">
                <a-radio-button value="">全部</a-radio-button>
                <!--              <a-radio-button value="NOT">未开始</a-radio-button>-->
                <a-radio-button value="DOING">进行中</a-radio-button>
                <a-radio-button value="COMPLETED">已完成</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="区域选择">
              <area-selection @onclickArea="onclickArea" @search="search"
              ></area-selection>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="rowClick"
        :rowKey="(record) => record.id"
        :scroll="{ x: 1700 }">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="reportNames" slot-scope="text, record">
          <a-tag v-for="(item,index) in record.reportNames" :key="index" color="#E9967A" style="margin: 3px;">{{ item }}</a-tag>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="record.status === 'NOT'">未开始</a-tag>
          <a-tag v-if="record.status === 'DOING'" color="#87d068">进行中</a-tag>
          <a-tag v-if="record.status === 'COMPLETED'" color="#108ee9">已完成</a-tag>
        </template>
        <template slot="executeTime" slot-scope="text, record">
          <span>{{ record.startTime }} ~ {{ record.endTime }}</span>
        </template>
        <template slot="carInfo" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              <span v-if="record.status === 'COMPLETED'">点击可更改默认车辆，并重新绘制车辆运行轨迹且更新清运吨数。</span>
            </template>
            <a v-if="record.status === 'COMPLETED'" @click.stop="setDefaultCar(record.id,record.driver.id)">{{ record.carInfo.platNumber }}</a>
            <span v-else>{{ record.carInfo.platNumber }}</span>
          </a-tooltip>
        </template>
        <template slot="mileage" slot-scope="text, record">
          {{ record.mileage }} | <a @click.stop="setModifyMileage(record)">{{ record.modifyMileage }}</a>
        </template>
        <span slot="action" slot-scope="text, record">
          <template>
            <a-button type="default" @click.stop="deleteOne(record, '确认要删除该条记录吗？删除后不可恢复！')"
                      shape="circle" icon="delete" size="small" style="color: #ff644e"></a-button>
          </template>
        </span>
      </a-table>
    </a-card>
    <!--修改清运记录-->
    <a-modal
      title="修改清运记录"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading">
      <car-loop-record-form ref="editRef" @editSuccess="editSuccess" @editError="editError"></car-loop-record-form>
    </a-modal>
    <!--详情modal-->
    <!--<a-modal :title="platNumber"
             :visible="detailDrawer"
             :width="1400"
             centered
             :footer="null"
             @cancel="detailClose">
      <LoopRecordDetail :dbDevicesData="dbDevicesData" ref="viewRef"></LoopRecordDetail>
    </a-modal>-->
    <a-drawer title="清运详细信息"
              placement="right"
              :closable="true"
              @close="detailClose"
              :mask="false"
              width="1600px"
              :visible="detailDrawer">
      <DrawerDetail :dbDevicesData="dbDevicesData" ref="viewRef"></DrawerDetail>
    </a-drawer>
    <!--设置默认车辆Modal-->
    <a-modal
      title="更改默认车辆"
      :visible="defaultModal"
      @cancel="closeDefaultModal"
      :footer="null">
      <a-form>
        <a-form-item label="">
          <a-col :span="6" v-for="item in driverCarList" :key="item.id">
            <a-button @click="setDefaultOk(item)">{{ item.platNumber }}</a-button>
          </a-col>
        </a-form-item>
      </a-form>
    </a-modal>
    <!--设置默认车辆Modal-->
    <a-modal title="更改执行公里"
             width="400px"
             :visible="modifyMileageModal"
             @ok="setModifyMileageOk"
             @cancel="closeModifyMileageModal">
      <a-form :form="form">
        <a-form-item label="执行公里" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
          <a-input-number style="width: 100%;" :step="0.1" v-decorator="[ 'modifyMileage', { rules: [{ type: 'number', required: true, message: '执行公里必须为数字' }],
                  trigger: 'change', initialValue: 0, }, ]" placeholder="请输入执行公里数"/>
        </a-form-item>
      </a-form>
    </a-modal>
    <!--导出文件，选择本地路径-->
    <a-modal v-model="selectPathVisible" title="清运记录图片导出">
      <div class="export-tip">导出清运图片的时间段不能大于一个月</div>
      <div>文件默认存储路径：<span style="font-weight: bold">C:\qy\files</span></div>
      <!--      <a-row>-->
      <!--        <a-col :span="18">-->
      <!--          <a-input v-model="selectedPath" placeholder="请选择文件路径"/>-->
      <!--        </a-col>-->
      <!--        <a-col :span="2">-->
      <!--        </a-col>-->
      <!--        <a-col :span="4">-->
      <!--          <input type="file" id="file" hidden @change="fileChange" webkitdirectory directory multiple>-->
      <!--          <a-button type="primary" style="font-size: 13px" @click="selectBrowseFolder">-->
      <!--            选择-->
      <!--          </a-button>-->
      <!--        </a-col>-->
      <!--      </a-row>-->
      <template slot="footer">
        <a-button type="primary" :loading="exportImagesLoading" @click="confirmExportFile">
          确定
        </a-button>
      </template>
    </a-modal>
  </page-layout>
</template>

<script>
import entityCRUDWithCopy from '../../../common/mixins/entityCRUDWithCopy'
import PageLayout from '@/components/page/PageLayout'
import SERVICE_URLS from '@/api/service.url'
import { carLoopRecordColumns, statusList } from './common/common'
import CarLoopRecordForm from './Form'
import DrawerDetail from './DrawerDetail'
// import LoopRecordDetail from './Detail'
import VueCookie from 'vue-cookie'
import '../../../../plugins/fileDownload/download.min'
import { DEFAULT_X_AUTH_TOKEN } from '../../../../store/mutation-types'
import moment from 'moment'

import AreaSelection from '../../statistics/loophistory/AreaSelection'

export default {
  name: 'List',
  mixins: [entityCRUDWithCopy],
  components: { DrawerDetail, CarLoopRecordForm, PageLayout, AreaSelection },
  data () {
    return {
      entityBaseUrl: SERVICE_URLS.csgz.carLoopRecordApi,
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        driverId: undefined,
        carInfoId: undefined,
        status: '',
        startTime: '',
        endTime: '',
        areaNo: ''
      },
      initColumns: carLoopRecordColumns(),
      statusList: statusList(),
      orgTree: [],
      userList: [], //下发/制定人下拉数据
      carList: [],
      driverCarList: [],
      dbDevicesData: [],
      valueStyle: {
        color: '',
        textAlign: 'center'
      },
      statisticsData: {},
      dateSearch: null, //日期绑定数据
      platNumber: '', //车牌号
      executeUserStr: undefined,
      detailDrawer: false,
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
      },
      exportCarLoading: false,
      exportImagesLoading: false,
      exportDriverLoading: false,
      exportLoading: false,
      recordId: null,
      driverId: null,
      defaultModal: false, //设置默认车辆弹出框
      modifyMileageModal: false, //设置公里数弹出框
      form: this.$form.createForm(this),
      selectPathVisible: false,
      selectedPath: ''
    }
  },
  created () {
    this.getOrgTree()
    this.loadUserList()
    this.loadCarInfoList()
  },
  methods: {
    deleteOne (record, content) {
      if (moment(record.startTime).isSame(moment(new Date()), 'day') || moment(record.startTime).isSame((moment().subtract(1, 'day')), 'day')) {
        this.handleDelete(record.id, content)
      } else {
        this.$message.warning('只能删除当天或昨日记录！')
      }
    },
    //设置公里数
    setModifyMileage (record) {
      if (record.status === 'COMPLETED' && (moment(record.endTime).isSame(moment(new Date()), 'day') || moment(record.endTime).isSame((moment().subtract(1, 'day')), 'day'))) {
        this.recordId = record.id
        this.modifyMileageModal = true
      } else {
        this.$message.warning('只能修改当天或昨日已完成的清运！')
      }
    },
    setModifyMileageOk (modifyMileage) {
      this.form.validateFields(
        (err, values) => {
          if (!err) {
            this.$http(this, {
              url: this.entityBaseUrl.setModifyMileage,
              data: {
                recordId: this.recordId,
                modifyMileage: values.modifyMileage
              },
              noTips: true,
              success: () => {
                this.modifyMileageModal = false
                this.search()
              }
            })
          }
        })
    },
    closeModifyMileageModal () {
      this.modifyMileageModal = false
    },
    //设置默认车辆
    setDefaultCar (recordId, driverId) {
      this.recordId = recordId
      this.driverId = driverId
      this.loadDriverCarInfoList()
      this.defaultModal = true
    },
    closeDefaultModal () {
      this.defaultModal = false
    },
    setDefaultOk (carInfo) {
      this.$http(this, {
        url: this.entityBaseUrl.setDefault,
        data: {
          recordId: this.recordId,
          carInfoId: carInfo.id
        },
        noTips: true,
        success: () => {
          setTimeout(() => {
            this.defaultModal = false
            this.search()
          }, 800)
        }
      })
    },
    loadCarInfoList () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.carInfoApi.select,
        noTips: true,
        success: (data) => {
          this.carList = data.body
        }
      })
    },
    loadDriverCarInfoList () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.carConfigApi.select_car_by_driver,
        noTips: true,
        data: {
          driver: this.driverId
        },
        success: (data) => {
          this.driverCarList = data.body
        }
      })
    },
    loadUserList () {
      this.$http(this, {
        url: SERVICE_URLS.organization.orgUserTree,
        data: {
          authPrefix: 'car_',
          chooseType: 'RADIO'
        },
        noTips: true,
        success: (data) => {
          this.userList = data.body
        }
      })
    },
    getOrgTree () {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        noTips: true,
        success: (data) => {
          this.orgTree = data.body
        }
      })
    },
    rowClick (record) {
      return {
        on: {
          click: () => {
            if (record.status === 'COMPLETED') {
              this.platNumber = '车牌号：' + record.carInfo.platNumber
              this.handleView(record.id)
            } else {
              this.$message.warning('任务未完成，详情未生成。')
            }
          }
        }
      }
    },
    detailClose () {
      this.detailDrawer = false
    },
    //日期选择
    dateOnChange (date, dateString) {
      this.searchParams.startTime = dateString[0]
      this.searchParams.endTime = dateString[1]
      this.search()
    },
    /*重置*/
    resetForm () {
      this.executeUserStr = undefined
      this.dateSearch = null
      this.searchParams.startTime = ''
      this.searchParams.endTime = ''
      Object.assign(this.searchParams, this.initParams)
      this.search()
    },
    beforeSearch () {
      this.searchParams.driverId = this.executeUserStr ? this.executeUserStr.split('_')[2] : undefined
      this.search()
    },
    selectStatus () {
      this.search()
    },
    //重写search和tableChange方法实现列表车辆任务名称Tag展示
    search () {
      this.$http(this, {
        url: this.entityBaseUrl.search,
        noTips: true,
        loading: 'tableLoading',
        data: this.searchParams,
        success: (data) => {
          this.tableData = data.body.content
          this.pagination.total = data.body.totalElements
          this.pagination.current = data.body.number + 1
          this.pagination.pageSize = data.body.size
          this.searchParams.size = data.body.size
          this.searchParams.number = data.body.number
          /*for (let item of this.tableData) {
            item.reportNames = eval(item.reportNames)
          }*/
          this.pagination.total = data.body.totalElements
        }
      })
    },
    tableChange (pagination, filters, sorter) {
      this.searchParams.size = pagination.pageSize
      this.searchParams.number = pagination.current - 1
      if (sorter.order === 'ascend') {
        this.searchParams.direction = 'ASC'
      } else {
        this.searchParams.direction = 'DESC'
      }
      this.searchParams.order = sorter.field
      this.search()
    },
    exportWord () {
      if ((this.searchParams.startTime !== '') && (this.searchParams.startTime !== '')) {
        const fileName = '清运记录'
        this.searchParams.exportType = 'driver_car'
        this.handExportWord(fileName)
      } else {
        this.$message.warning('请选择执行时间')
      }
    },
    exportByDriver () {
      if ((this.searchParams.startTime !== '') && (this.searchParams.startTime !== '')) {
        this.exportDriverLoading = true
        this.$http(this, {
          url: SERVICE_URLS.csgz.carLoopRecordApi.exportExcel,
          noTips: true,
          data: {
            exportType: 'driver',
            startTime: this.searchParams.startTime,
            endTime: this.searchParams.endTime,
            areaNo: this.searchParams.areaNo,
            driverId: this.searchParams.driverId
          },
          success: (data) => {
            let downUrl = SERVICE_URLS.document.download.path.replace('{id}', data.body.id) + '?attname=' + encodeURIComponent(data.body.name)
            const xAuthToken = VueCookie.get(DEFAULT_X_AUTH_TOKEN)
            if (!xAuthToken) {
              this.$Message.error({
                closable: true,
                content: '下载凭证丢失，请重新登录！',
                duration: 3
              })
              return
            }
            downUrl += '&' + DEFAULT_X_AUTH_TOKEN + '=' + xAuthToken
            // eslint-disable-next-line
            downloadFile(downUrl)
            this.exportDriverLoading = false
          }, error: () => {
            this.exportDriverLoading = false
          }
        })
      } else {
        this.$message.warning('请选择执行时间')
      }
    },
    exportByCar () {
      if ((this.searchParams.startTime !== '') && (this.searchParams.startTime !== '')) {
        this.exportCarLoading = true
        this.$http(this, {
          url: SERVICE_URLS.csgz.carLoopRecordApi.exportExcel,
          noTips: true,
          data: {
            exportType: 'car',
            startTime: this.searchParams.startTime,
            endTime: this.searchParams.endTime,
            areaNo: this.searchParams.areaNo,
            carInfoId: this.searchParams.carInfoId
          },
          success: (data) => {
            let downUrl = SERVICE_URLS.document.download.path.replace('{id}', data.body.id) + '?attname=' + encodeURIComponent(data.body.name)
            const xAuthToken = VueCookie.get(DEFAULT_X_AUTH_TOKEN)
            if (!xAuthToken) {
              this.$Message.error({
                closable: true,
                content: '下载凭证丢失，请重新登录！',
                duration: 3
              })
              return
            }
            downUrl += '&' + DEFAULT_X_AUTH_TOKEN + '=' + xAuthToken
            // eslint-disable-next-line
            downloadFile(downUrl)
            this.exportCarLoading = false
          }, error: () => {
            this.exportCarLoading = false
          }
        })
      } else {
        this.$message.warning('请选择执行时间')
      }
    },
    onclickArea (areaCodes) {
      this.searchParams.areaNo = areaCodes.join(',')
    },
    exportFile () {
      this.selectPathVisible = true
    },
    confirmExportFile () {
      this.exportImagesLoading = true
      this.$http(this, {
        url: SERVICE_URLS.csgz.carLoopRecordApi.exportImages,
        noTips: true,
        data: this.searchParams,
        success: (data) => {
          let downUrl = SERVICE_URLS.document.download.path.replace('{id}', data.body.id) + '?attname=' + encodeURIComponent(data.body.name)
          const xAuthToken = VueCookie.get(DEFAULT_X_AUTH_TOKEN)
          if (!xAuthToken) {
            this.$Message.error({
              closable: true,
              content: '下载凭证丢失，请重新登录！',
              duration: 3
            })
            return
          }
          downUrl += '&' + DEFAULT_X_AUTH_TOKEN + '=' + xAuthToken
          // eslint-disable-next-line
          downloadFile(downUrl)
          this.exportImagesLoading = false
        }, error: () => {
          this.exportImagesLoading = false
        }
      })
    },
    fileChange (e) {
      try {
        const fu = document.getElementById('file')
        console.log('fu=', fu)
        if (fu == null) return
        this.selectedPath = fu.files[0].path
        console.log('selectedPath=', this.selectedPath)
      } catch (error) {
        console.debug('choice file err:', error)
      }
    },
    selectBrowseFolder () {
      const file = document.getElementById('file')
      file.click()
    }
  }
}
</script>

<style scoped>
.export-tip {
  margin-bottom: 15px;
  font-size: 13px;
  color: red;
  margin-top: -10px;
}
</style>
