// 清运任务信息列表表头
export const carLoopRecordColumns = () => {
  return [
    {
      title: '序号',
      width: 55,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '执行司机',
      width: 80,
      align: 'left',
      dataIndex: 'driver.name'
    },
    {
      title: '执行时间',
      width: 320,
      align: 'left',
      scopedSlots: { customRender: 'executeTime' }
    },
    {
      title: '执行车辆',
      width: 120,
      align: 'center',
      dataIndex: 'carInfo.platNumber',
      scopedSlots: { customRender: 'carInfo' }
    },
    {
      title: '所属部门',
      width: 120,
      align: 'center',
      dataIndex: 'department.name'
    },
    {
      title: '车辆任务',
      align: 'left',
      scopedSlots: { customRender: 'reportNames' }
    },
    {
      title: '执行公里',
      width: 140,
      align: 'center',
      dataIndex: 'mileage',
      scopedSlots: { customRender: 'mileage' }
    },
    {
      title: '清运吨数',
      width: 100,
      align: 'center',
      dataIndex: 'carInfo.volume'
    },
    {
      title: '清运时长',
      width: 100,
      align: 'center',
      dataIndex: 'durationStr'
    },
    {
      title: '排放污水厂',
      width: 140,
      align: 'center',
      ellipsis: true,
      dataIndex: 'destination'
    },
    {
      title: '执行状态',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'status' }
    },
    /*{
      title: '清运概述',
      width: 200,
      align: 'left',
      dataIndex: 'summary',
      ellipsis: true,
      customRender: (text, record) => {
        const time = `${record.summary ? record.summary : '-'}` //es6写法
        return time
      }
    },*/
    {
      title: '操作',
      align: 'center',
      width: '80px',
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

// 清运任务状态
export const statusList = function () {
  return [
    { value: 'NOT', label: '未开始' },
    { value: 'DOING', label: '进行中' },
    { value: 'COMPLETED', label: '已完成' }
  ]
}


